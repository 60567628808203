/**
 * Generic table.
 */

.ui-table-wrapper {
    border-bottom: 1px solid var(--grey-a400);
}

.ui-table {
    width: 100%;
    line-height: 2.5;
}

.ui-table td,
.ui-table th {
    padding: 0 10px 0 0;
    padding: 0 1rem 0 0;
    box-sizing: border-box;
}

.ui-table td:last-child,
.ui-table th:last-child {
    padding-right: 0;
}

.ui-table .left {
    text-align: left;
}

.ui-table .center {
    text-align: center;
}

.ui-table .right {
    text-align: right;
}

.ui-table .head {
    color: var(--grey-a700);
    font-size: var(--font-size-caption);
    line-height: 1.3;
    border-bottom: 1px solid var(--grey-a400);
}

.ui-table .ui-table {
    margin: 10px 0;
}

.ui-table .head .col-heading {
    font-weight: bold;
    font-size: var(--font-size-body1);
    text-transform: none;
}

.ui-table .ui-table .head {
    border-color: var(--grey-300);
}

.ui-table .row:not(:first-child) {
    border-top: 1px solid var(--grey-300);
}

.ui-table .row:last-child {
    border-bottom: 1px solid var(--grey-300);
}

/* Table with more space in cells. */

.ui-table.spacious td,
.ui-table.spacious th {
    padding-right: 15px;
    padding-right: 1.5rem;
}

.ui-table.spacious td:last-child,
.ui-table.spacious th:last-child {
    padding-right: 0;
}

.ui-table.spacious th {
    line-height: 3;
    font-size: var(--font-size-body1);
}

.ui-table.spacious td {
    line-height: 3.5;
}

/*Overwrite the default top: 12px when inside ui-table module */
.ui-table .k-radio .radio-icon,
.ui-table .k-radio .invisible {
    top: 0;
}

/* separator row used in sizes 1 and 2*/
.ui-table .ui-table .separator-row {
    display: none;
}

/* 
    Breakpoint 1 + Breakpoint 2
*/
@media only screen and (max-width: 767px) {
    .ui-table .ui-table .row {
        border-top: 0;
    }
}

/*
    Breakpoint 4
*/
@media only screen and (min-width: 920px) and (max-width: 1024px) {
    .ui-table td, .ui-table th {
        padding-right: .7rem;
    }
}
